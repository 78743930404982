import { fetchLendersTerms } from '@/api/lendersApi';
import Button from '@/components/Button';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { CreateTermsModal } from './CreateTermsModal';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';
import { motion, AnimatePresence } from 'framer-motion';
import DeleteTermsModal from './DeleteTermsModal';
import { usePermissions } from '@/hooks/usePermissions';
import { UpdateTermsModal } from './UpdateTermsModal';
import { AssignLendersModal } from './AssignLendersModal';

export const LendersTermsTable = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canCreateLender = hasPermission('lender', 'create');
  const canUpdateLender = hasPermission('lender', 'update');
  const canDeleteLender = hasPermission('lender', 'delete');

  const lenderTerms = useQuery({
    queryKey: ['lenderTerms'],
    queryFn: fetchLendersTerms
  });

  const terms = lenderTerms?.data?.data;

  const handleCreate = () => {
    if (!canCreateLender || isASuperAdmin) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateModal(true);
    }
  };

  const handleUpdate = (obj, id) => {
    if (!canUpdateLender || isASuperAdmin) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedTerm(obj);
      setShowUpdateModal(true);
    }
  };

  const handleAssign = (obj) => {
    if (!canUpdateLender || isASuperAdmin) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedTerm(obj);
      setShowAssignModal(true);
    }
  }

  // const handleUpdate = async (obj) => {
  //   if (!canUpdateLender || isASuperAdmin) {
  //     toast.error('You do not have permission to update');
  //     return;
  //   }
  //   try {
  //     const detailedTerm = await fetchTermById(obj.id); // Replace with your API call
  //     setSelectedTerm(detailedTerm);
  //     setShowUpdateModal(true);
  //   } catch (error) {
  //     toast.error('Failed to fetch term details');
  //   }
  // };

  const handleDelete = (obj) => {
    if (!canDeleteLender || isASuperAdmin) {
      toast.error('You do not have permission to delete');
      return;
    } else {
      setShowDeleteModal(true);
      setSelectedTerm(obj);
    }
  };

  if (lenderTerms.isFetching) {
    return <TableLoader />;
  }

  return (
    <TermsListView>
      <TableHeader>
        <Button
          width="20%"
          height="48px"
          type="button"
          onClick={() => {
            handleCreate()
            // setShowCreateModal(true);
          }}>
          Create Terms
        </Button>
      </TableHeader>
      <TableHead>
        <div className="plan">
          <span>Financing Type</span>
        </div>
        <div>
          <span>Interest</span>
        </div>
        <div>
          <span>Tenure</span>
        </div>
        <div>
          <span>Date Created</span>
        </div>
        <div></div>
      </TableHead>
      <TableBody>
        {terms.map((item, i) => {
          return (
            <div key={item?.id} className="items">
              <div className="plan">
                <span>
                  {item?.type
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase() || '-')}
                </span>
              </div>
              <div>
                <span>{item?.interest || '-'}%</span>
              </div>
              <div>
                <span>
                  {item?.tenure || '-'} {item?.tenure === 1 ? 'day' : 'days'}
                </span>
              </div>
              <div>
                <span>{formatInTimeZone(new Date(item?.createdAt), 'UTC', 'do MMM, yyy')}</span>
                <p>{formatInTimeZone(new Date(item?.createdAt), 'UTC', 'h:mm aa')}</p>
              </div>
              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      ref={ref}
                      className="menu-actions"
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          // handleUpdate(item, item?.id);
                          handleUpdate(item);
                        }}>
                        <span>Update Term</span>
                      </button>
                      <button
                        onClick={() => {
                          // handleUpdate(item, item?.id);
                          handleAssign(item);
                        }}>
                        <span>Assign Lenders</span>
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(item);
                        }}>
                        <span className="delete">Delete Term</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>
      <CreateTermsModal
        showModal={showCreateModal}
        setShowModal={() => setShowCreateModal(false)}
      />

      <AssignLendersModal 
        showModal={showAssignModal}
        setShowModal={setShowAssignModal}
        selectedTerm={selectedTerm}
      />

      <DeleteTermsModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        selectedTerm={selectedTerm}
      />

      <UpdateTermsModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        termData={selectedTerm}
      />
    </TermsListView>
  );
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const TermsListView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }
  }

  .plan {
    flex: 1.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }

      p {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .plan {
      flex: 1.5;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }

          .delete {
            color: ${({ theme }) => theme.colors?.error_200};
          }
        }
      }
    }

    /* div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    } */
  }
`;
