import { fetchWalletLedger } from '@/api/walletApi';
import PageHeader from '@/components/PageHeader';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import PageLoader from '@/loaders/PageLoader';
import { formatInTimeZone } from 'date-fns-tz';

export const HistoryDetails = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const history = useQuery({
    queryKey: ['history', { id }],
    queryFn: fetchWalletLedger
  });

  const [currentDetailPage, setCurrentDetailPage] = useState(1);
  const params = new URLSearchParams(search);
  const currentPage = params.get('page');

  const detail = history?.data?.data;

  useEffect(() => {
    if (currentPage) {
      setCurrentDetailPage(parseInt(currentPage));
    }
  }, [currentPage]);

  const handleReturn = () => {
    window.history.back();
  };

  if (history.isFetching) {
    return <PageLoader />
  }

  return (
    <>
      <PageHeader title="Wallet History Details" subtitle="View and manage wallet history" />
      <HistoryDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Status</h3>
                <Status status={detail?.status}>{detail?.status || '--'}</Status>
              </div>
            </div>

            <div className="item">
              <div className="label-view">
                <h3>Channel</h3>
                <p className="name">{detail?.channel || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Amount</h3>
                <p>₦{numberWithCommas(detail?.amount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Previous Balance</h3>
                <p>₦{numberWithCommas(detail?.previousBalance || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Current Balance</h3>
                <p>₦{numberWithCommas(detail?.currentBalance || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Type</h3>
                <p>{detail?.event || '-'}</p>
              </div>
            </div>
          </FormData>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Sender</h3>
                <p className="name">{detail?.senderName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Recipient</h3>
                <p className="name">{detail?.receiverName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Remark</h3>
                <p>{detail?.remark}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Initiated</h3>
                <p>
                  {detail?.createdAt
                    ? formatInTimeZone(new Date(detail.createdAt), 'UTC', 'do MMM, yyyy, h:mm aa')
                    : '-'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Paid</h3>
                <p>
                  {detail?.updatedAt
                    ? formatInTimeZone(new Date(detail.updatedAt), 'UTC', 'do MMM, yyyy, h:mm aa')
                    : '-'}
                </p>
              </div>
            </div>
          </FormData>
        </DataView>
      </HistoryDetailsView>
    </>
  );
}

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'successful':
        return '#16A34A';
      case 'pending':
        return '#F4B740';
      case 'processing':
        return '#F4B740';
      case 'initiated':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'failed':
        return '#B91C1C';
      case 'declined':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const HistoryDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .name {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;