import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActiveWalletsTable from '../ActiveWalletsTable';
import InactiveWalletsTable from '../InactiveWalletsTable';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const WalletStateTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
    let view = searchParams.get('view');
    let tab = searchParams.get('tab');
    // let tab = searchParams.get('view');
    const navigate = useNavigate();
  
    useEffect(() => {
      // Check if 'tab' parameter is not present in the URL
      if (!searchParams.get('tab') || !searchParams.get('view')) {
        // Set the default URL parameters for the "Subscription Plans" tab
        setSearchParams({ tab: 'wallets', view: 'active' });
        // Optionally, you can also navigate to the new URL
        navigate('?tab=wallets&view=active');
      }
    }, [searchParams, navigate, setSearchParams]);

  const tabs = [
    {
      name: 'Active Wallets',
      path: 'view=active',
      value: 'active'
    },
    {
      name: 'Inactive Wallets',
      path: 'view=inactive',
      value: 'inactive'
    }
  ];

  return (
    <Tab>
      <TabView>
        {tabs.map((tabItem) => (
          <button
            key={tabItem.path}
            className={`tabs ${view === tabItem.value ? 'active' : ''}`}
            onClick={() => setSearchParams({ tab: 'wallets', view: tabItem.value })}>
            {tabItem.name}
          </button>
        ))}
      </TabView>
      <TabContent>
        {view === 'active' && <ActiveWalletsTable />}
        {view === 'inactive' && <InactiveWalletsTable />}
      </TabContent>
    </Tab>
  );
};

const Tab = styled.div`
  width: 100%;
  height: 100%;
`;

const TabView = styled.div`
  height: 50px;
  display: flex;
  z-index: 10;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};  

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;