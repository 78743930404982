import { fetchTransactionFees } from "@/api/transactionApi";
import Button from "@/components/Button";
import useOnClickOutside from "@/hooks/useClickOutside";
import TableLoader from "@/loaders/TableLoader";
import { numberWithCommas } from "@/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion';
import styled from "styled-components"
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import CreateModal from "./CreateModal";
import { usePermissions } from "@/hooks/usePermissions";
import toast from "react-hot-toast";
import UpdateTransactionFee from "./UpdateTransactionFee";

const TransactionFees = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  // const [selectedId, setSelectedId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  // const [showUpdateModal, setShowUpdateModal] = useState(false);

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canCreateFee = hasPermission('transaction', 'create');
  const canUpdateFee = hasPermission('transaction', 'update');

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const fees = useQuery({
    queryKey: ['fees'],
    queryFn: fetchTransactionFees,
    keepPreviousData: true
  })

  const feesData = fees.data?.data?.items;

  const handleCreate = () => {
    const isAllowed = canCreateFee || isASuperAdmin;
    if(!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateModal(!showCreateModal);
    }
  }

  const handleUpdate = (feeId) => {
    const isAllowed = canUpdateFee || isASuperAdmin;
    if(!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      // setSelectedId(feeId);
      // setShowUpdateModal(true);
      navigate(`/transactions/fees/${feeId}`)
    }
  }

  if (fees.isFetching) {
    return <TableLoader />
  }

  return (
    <TransactionFeesView>
      <TableHeader>
        <Button
          width="20%"
          height="48px"
          type="button"
          onClick={() => {
            handleCreate();
          }}>
          Create Fee
        </Button>
      </TableHeader>
      <TableHead>
        <div>
          <span>Catergory</span>
        </div>
        <div>
          <span>Type</span>
        </div>
        <div>
          <span>Min. Amount</span>
        </div>
        <div>
          <span>Max. Amount</span>
        </div>
        <div>
          <span>Percentage</span>
        </div>
        <div>
          <span>Flat</span>
        </div>
        <div>
          <span>Status</span>
        </div>
        <div>
          <span>V.A.T</span>
        </div>
        <div></div>
      </TableHead>
      <TableBody>
        {feesData.map((fee, i) => {
          return (
            <div key={fee?.id} className="items">
              <div>
                <span>{fee?.category}</span>
              </div>
              <div>
                <span>{fee?.type}</span>
              </div>
              <div>
                <span>₦{numberWithCommas(fee?.min)}</span>
              </div>
              <div>
                <span>₦{numberWithCommas(fee?.max)}</span>
              </div>
              <div>
                <span>{fee?.percentage}%</span>
              </div>
              <div>
                <span>{fee.flat}</span>
              </div>
              <Status status={fee?.status}>
                <span className="dot"></span> {fee?.status || '-'}
              </Status>
              <div>
                <span>{fee?.vat}</span>
              </div>
              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      ref={ref}
                      className="menu-actions"
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          handleUpdate(fee?.id);
                        }}>
                        <span>Update Fee</span>
                      </button>
                      {/* <button
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedSubscription(item);
                        }}>
                        <span className="delete">Delete Subscription</span>
                      </button> */}
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          )
        })}
      </TableBody>

      <CreateModal 
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
      />

      {/* <UpdateTransactionFee 
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      /> */}
    </TransactionFeesView>
  )
}

export default TransactionFees

const TransactionFeesView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 60px;
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'inactive':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }
  }

  .plan {
    flex: 1.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .plan {
      flex: 1.5;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }

          .delete {
            color: ${({ theme }) => theme.colors?.error_200};
          }
        }
      }
    }
  }
`;
