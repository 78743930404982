import { fetchVirtualAccount } from "@/api/virtualAccountsApi";
import PageHeader from "@/components/PageHeader";
import PageLoader from "@/loaders/PageLoader";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { formatInTimeZone } from "date-fns-tz";
import { useEffect, useState } from "react";

const VirtualAccountDetails = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();

  const virtualAccount = useQuery({
    queryKey: ['virtualAccount', { id }],
    queryFn: fetchVirtualAccount
  })

  const [currentDetailPage, setCurrentDetailPage] = useState(1);
    
    const params = new URLSearchParams(search);
    const currentPage = params.get('page');

  const details = virtualAccount?.data?.data

  const handleReturn = () => {
    window.history.back();
  };

  useEffect(() => {
    if (currentPage) {
      setCurrentDetailPage(parseInt(currentPage));
    }
  }, [currentPage]);

  if (virtualAccount.isFetching) {
    return <PageLoader />
  }
  return (
    <>
      <PageHeader title="Virtual Accounts Details" subtitle="View and manage virtual accounts" />
      <VirtualAccountDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Account Status</h3>
                <Status status={details?.status}>{details?.status || '--'}</Status>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Name</h3>
                <p>{details?.accountName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Number</h3>
                <p>{details?.accountNumber || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Provider</h3>
                <p className="name">{details?.provider || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>
                  {formatInTimeZone(new Date(details?.createdAt), 'UTC', 'do MMM, yyy, h:mm aa') || '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Updated</h3>
                <p>
                  {formatInTimeZone(new Date(details?.updatedAt), 'UTC', 'do MMM, yyy, h:mm aa') || '--'}
                </p>
              </div>
            </div>
          </FormData>

          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>User Id</h3>
                <p>{details?.user?.id}</p>
              </div>
              <div className="copy-view">
                <button
                  onClick={() => {
                    navigate(`/users/${details?.user?.id}`);
                  }}>
                  View <ExtLinkIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Fullname</h3>
                <p>{`${details?.user?.firstName} ${details?.user?.lastName}`}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Email Address</h3>
                <p>{details?.user?.email}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Company Name</h3>
                <p>{details?.company?.name}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>
                  {formatInTimeZone(new Date(details?.company?.createdAt), 'UTC', 'do MMM, yyy, h:mm aa') || '--'}
                </p>
              </div>
            </div>
          </FormData>
        </DataView>
      </VirtualAccountDetailsView>
    </>
  );
}

export default VirtualAccountDetails

const VirtualAccountDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#16A34A';
      case 'pending':
        return '#F4B740';
      case 'processing':
        return '#F4B740';
      case 'initiated':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'inactive':
        return '#B91C1C';
      case 'declined':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .name {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;