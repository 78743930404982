import { fetchUserActivity, fetchUserTimeline, fetchUserTransactionActivity } from '@/api/userApi';
import TableLoader from '@/loaders/TableLoader';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export const UserActivity = () => {
  const { id } = useParams();

  const activity = useQuery({
    queryKey: ['activity', { id }],
    queryFn: fetchUserActivity
  });

  const transaction = useQuery({
    queryKey: ['transaction', { id }],
    queryFn: fetchUserTransactionActivity
  })

  const timeline= useQuery({
    queryKey: ['timeline', { id }],
    queryFn: fetchUserTimeline
  });

  const info = timeline?.data?.data;

  const detail = activity?.data?.data;

  const trxn = transaction?.data?.data;

  if (activity.isFetching || timeline.isFetching) {
    return <TableLoader />;
  }

  return (
    <UserActivityView>
      <DataView>
        <FormData>
          <div className="head-title">
            <h3>Bill/Invoice Activity</h3>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Inflows</h3>
              <p>{numberWithCommas2(detail?.inflows || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Outflows</h3>
              <p>{numberWithCommas2(detail?.outflows || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Invoices</h3>
              <p>{numberWithCommas2(detail?.total_invoice || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Paid Invoices</h3>
              <p>{numberWithCommas2(detail?.paid_invoice?.total_paid_invoice || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Paid Invoices Amount</h3>
              <p>₦{numberWithCommas(detail?.paid_invoice?.total_paid_invoice_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Partially Paid Invoices</h3>
              <p>{numberWithCommas2(detail?.partially_paid_invoice?.total_count || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Partially Paid Invoices Amount</h3>
              <p>₦{numberWithCommas(detail?.partially_paid_invoice?.total_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Unpaid Invoices</h3>
              <p>{numberWithCommas2(detail?.unpaid_invoice?.total_unpaid_invoice || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Unpaid Invoices Amount</h3>
              <p>
                ₦{numberWithCommas(detail?.unpaid_invoice?.total_unpaid_invoice_amount || '00.00')}
              </p>
            </div>
          </div>
        </FormData>
        <FormData>
          <div className="head-title">
            <h3>Bill/Invoice Activity</h3>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Bills</h3>
              <p>{numberWithCommas2(detail?.bill?.bill_invoice_activity_count || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Bills Amount</h3>
              <p>₦{numberWithCommas(detail?.bill?.bill_invoice_activity_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Paid Bills</h3>
              <p>{numberWithCommas2(detail?.paid_bills?.total_paid_bills || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Paid Bills Amount</h3>
              <p>₦{numberWithCommas(detail?.paid_bills?.total_paid_bills_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Partially Paid Bills</h3>
              <p>
                {numberWithCommas2(detail?.partially_paid_bills?.total_partially_paid_bills || '0')}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Partially Paid Bills Amount</h3>
              <p>
                ₦
                {numberWithCommas(
                  detail?.partially_paid_bills?.total_partially_paid_bills_amount || '00.00'
                )}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Unpaid Bills</h3>
              <p>{numberWithCommas2(detail?.unpaid_bills?.total_unpaid_bills || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Unpaid Bills Amount</h3>
              <p>₦{numberWithCommas(detail?.unpaid_bills?.total_unpaid_bills_amount || '00.00')}</p>
            </div>
          </div>
        </FormData>
      </DataView>

      <DataView>
        <FormData>
          <div className="head-title">
            <h3>Transactions Activity</h3>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Transaction Count</h3>
              <p>{numberWithCommas2(trxn?.total_transaction || '0')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Transaction Inflow Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_transaction_inflows || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Transaction Outflow Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_transaction_outflows || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Max. Amount Credited</h3>
              <p>₦{numberWithCommas(trxn?.max_amount_credited || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Min. Amount Credited</h3>
              <p>₦{numberWithCommas(trxn?.min_amount_credited || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Max. Amount Debited</h3>
              <p>₦{numberWithCommas(trxn?.max_amount_debited || '00.00')}</p>
            </div>
          </div>
        </FormData>
        <FormData>
          <div className="head-title">
            <h3>Transactions Activity</h3>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Min. Amount Debited</h3>
              <p>₦{numberWithCommas(trxn?.min_amount_debited || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Credited Invoices Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_invoice_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Credited Deposit Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_amount_deposit || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Debited Bills Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_bills_amount || '00.00')}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Total Debited Withdrawal Amount</h3>
              <p>₦{numberWithCommas(trxn?.total_amount_withdrawal || '00.00')}</p>
            </div>
          </div>
        </FormData>
      </DataView>

      <DataView>
        <FormData2>
          <div className="head-title">
            <h3>Timeline Activity</h3>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Most Used Feature</h3>
              <p>{info?.mostUsedFeature?.type || '-'}</p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Date of Account Creation</h3>
              <p>
                {info?.accountCreation?.createdAt
                  ? formatInTimeZone(
                      new Date(info?.accountCreation?.createdAt),
                      'UTC',
                      'do MMM, yyyy, h:mm aa'
                    )
                  : '-'}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Compliance Approval Date</h3>
              <p>
                {info?.complianceDateApproval?.updatedAt
                  ? formatInTimeZone(
                      new Date(info?.complianceDateApproval?.updatedAt),
                      'UTC',
                      'do MMM, yyyy, h:mm aa'
                    )
                  : '-'}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Last Login</h3>
              <p>
                {detail?.last_login
                  ? formatInTimeZone(new Date(detail?.last_login), 'UTC', 'do MMM, yyyy, h:mm aa')
                  : '-'}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Date of Last Credit Transaction</h3>
              <p>
                {trxn?.date_of_last_credit_transaction
                  ? formatInTimeZone(
                      new Date(trxn?.date_of_last_credit_transaction),
                      'UTC',
                      'do MMM, yyyy, h:mm aa'
                    )
                  : '-'}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="label-view">
              <h3>Date of Last Debit Transaction</h3>
              <p>
                {trxn?.date_of_last_debit_transaction
                  ? formatInTimeZone(
                      new Date(trxn?.date_of_last_debit_transaction),
                      'UTC',
                      'do MMM, yyyy, h:mm aa'
                    )
                  : '-'}
              </p>
            </div>
          </div>
        </FormData2>
      </DataView>
    </UserActivityView>
  );
};

const UserActivityView = styled.div`
  width: 100%;
  height: 100%;
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-top: 30px;

  .col2 {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: space-between; */
  }
`;

const FormData = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.activeTitle};
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const FormData2 = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};
  margin-bottom: 20px;

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.activeTitle};
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;