import PageHeader from '@/components/PageHeader';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/attachment.icon.svg';
import PageLoader from '@/loaders/PageLoader';
import { toast } from 'react-hot-toast';
import { fetchArchivedInvoice } from '@/api/invoiceApi';
import { format } from 'date-fns';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';

function ArchivedInvoicesDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const invoice = useQuery({
    queryKey: ['invoice', { id }],
    queryFn: fetchArchivedInvoice,
    onSuccess: (data) => {}
  });

  const detail = invoice?.data?.data;

  const handleReturn = () => {
    window.history.back();
  };

  if (invoice.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title="Invoice details" subtitle="View and manage an Invoice" />
      <ArchivedInvoicesDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Invoice Status</h3>
                <Status status={detail?.status}>{detail?.status}</Status>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Invoice Type</h3>
                <p>{detail?.type || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Customer Name</h3>
                <p>{detail?.customer?.companyName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Invoice Id</h3>
                <p>{detail?.id || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Invoice No:</h3>
                <p>{detail?.billOrInvoiceNumber || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Frequency</h3>
                <p>{detail?.frequency || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Recurring Start Date</h3>
                <p>
                  {detail?.recurringStartDate
                    ? formatInTimeZone(new Date(detail?.recurringStartDate), 'UTC', 'do MM, yyyy, h:mm aa')
                    : '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Recurring End Date</h3>
                <p>
                  {detail?.recurringEndDate
                    ? formatInTimeZone(new Date(detail?.recurringEndDate), 'UTC', 'do MM, yyyy, h:mm aa')
                    : '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Next Recurring Date</h3>
                <p>
                  {detail?.nextRecurringAt
                    ? formatInTimeZone(new Date(detail?.nextRecurringAt), 'UTC', 'do MMM, yyyy, h:mm aa')
                    : '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Subject</h3>
                <p>{detail?.subject || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Amount</h3>
                <p>₦{numberWithCommas(detail?.amount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Tax</h3>
                <p>{detail?.tax || '0'}%</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>
                  {detail?.createdAt
                    ? formatInTimeZone(new Date(detail?.createdAt), 'UTC' ,'do MMM, yyyy, h:mm aa')
                    : '-'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Updated</h3>
                <p>
                  {detail?.updatedAt
                    ? formatInTimeZone(new Date(detail?.updatedAt), 'UTC', 'do MMM, yyyy, h:mm aa')
                    : '-'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Due Date</h3>
                <p>
                  {detail?.dueDate
                    ? formatInTimeZone(new Date(detail?.dueDate), 'UTC', 'do MMM, yyyy, h:mm aa')
                    : '-'}
                </p>
              </div>
            </div>
          </FormData>

          <ColumnView>
            <FormData>
              <div className="item">
                <div className="label-view">
                  <h3>User Id</h3>
                  <p>{detail?.userId}</p>
                </div>
                <div className="copy-view">
                  <button
                    onClick={() => {
                      navigate(`/users/${detail?.userId}`);
                    }}>
                    View <ExtLinkIcon />
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Business Name</h3>
                  <p>{detail?.user?.companies?.[0]?.name || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Description</h3>
                  <p>{detail?.description || '--'}</p>
                </div>
              </div>
              <div className="item">
                <div className="label-view">
                  <h3>Amount Paid</h3>
                  <p>₦{numberWithCommas(detail?.amount || '00.00')}</p>
                </div>
              </div>
            </FormData>

            {detail?.fileUrl && (
              <FileView>
                <h3>Attached files</h3>
                <p>Files and assets that have been attached to this submission</p>
                <FileItem>
                  <div className="icon">
                    <FileIcon />
                  </div>
                  <div className="label-view">
                    <h3>Invoice Attachment</h3>
                    <p>{detail?.fileUrl.substring(detail?.fileUrl.lastIndexOf('/') + 1)}</p>
                  </div>
                  <div className="view">
                    <a href={detail?.fileUrl} target="_blank" rel="noopener noreferrer">
                      View <ExtLinkIcon />
                    </a>
                  </div>
                </FileItem>
              </FileView>
            )}
          </ColumnView>
        </DataView>
      </ArchivedInvoicesDetailsView>
    </>
  );
}

export default ArchivedInvoicesDetail;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'sent':
        return '#16A34A';
      case 'paid':
        return '#16A34A';
      case 'approved':
        return '#16A34A';
      case 'partially-paid':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'draft':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'archived':
        return '#F4B740';
      case 'unsent':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const ArchivedInvoicesDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .progress-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const ColumnView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FileView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  padding-left: 20px;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme?.colors?.secondary};
    margin-bottom: 8px;
  }

  .verification {
    margin-top: 40px;
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.secondary};
  }

  .sub-text {
    margin-bottom: 20px;
  }
`;

const FileItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f3f2f2;
  border-radius: 6px;
  margin-top: 20px;

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors?.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label-view {
    width: calc(100% - 190px);

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .view {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: ${({ theme }) => theme?.colors?.info};
      font-size: 0.875rem;
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        width: 16px;
        height: 16px;

        path {
          stroke: ${({ theme }) => theme?.colors?.info};
        }
      }
    }
  }
`;
